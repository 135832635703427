@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.errorpage-video-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: #cccccc;
	z-index: -1;
}

.errorpage-background-video {
	z-index: -1;
	object-fit: cover;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
}

.errorpage-background-image {
	z-index: -1;
	object-fit: cover;
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
}

.error-btn-container-desktop {
	margin: 0 0px 100px 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	position: fixed;
	bottom: 5px;
	left: 60px;
	gap:16px;

}

.error-btn-container-tablet {
    margin: 0 0 -50px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-position: 100%;
	gap:16px;
}

.error-btn-container-mobile {
	margin: 0 0 60px 0;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background-position: 100%;
}

.error-btn-text-only {
	background: none;
	border: none;
	box-shadow: none;
	filter: none;
}

.error-page {
	.app-button-secondary {
		color: $color-white;
		font-weight: 700;
	}
	.app-button-primary {
		@include longButtonTexts;
	}
}

.error-btn-text-only:hover {
	background: none;
	border-radius: 50px;
	box-shadow: none;
	filter: none;
	color: #9b9b9b;
}

.error-btn-text-only:active {
	background: none;
	border-radius: 50px;
	box-shadow: none;
	filter: none;
	color: $color-black;
}

.btn-label {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	text-align: center;
	padding: 14px 20px;
}

.btn-label-primary {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	border: 2px solid $color-purple;
	border-radius: 50px;
	padding: 14px 20px;
	gap: 10px;
	text-align: center;
	color: $color-black;
	background: linear-gradient(0deg, #eee9ff, #eee9ff);
}

.btn-label-primary:hover {
	box-sizing: border-box;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px 20px;
	gap: 10px;

	background-color: none;
	background: none;
	border: 2px solid $color-purple;
	border-radius: 50px;
	color: $color-black;
}

.btn-label-primary:active {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px 20px;
	gap: 10px;
	background: $color-purple;
	border: 2px solid $color-purple;
	border-radius: 50px;
	color: $color-black;
}

.error-page-container-large {
	margin: 0 60px;
	display: flex;
	flex-direction: column;
}

.error-page-container-tablet {
	position: absolute;
    left: 0;
    bottom: 200px;
    right: 0;
}

.error-page-container-mobile {
	margin: 0 40px;
	display: flex;
	flex-direction: column;
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
}

.play-pause {
	position: absolute;
	margin: auto;
	top: 10px;
	left: 80%;
}
