@import "src/assets/sass/variables.scss";
@import "src/assets/sass/mixins.scss";

footer {
	@include footerColor();
	
	font-family: 'Slalom Sans';
	position: relative;
	width: 100%;
	height: 80px;
	color: rgba(255, 255, 255, 1);
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	cursor: default;
	font-size: 16px;
	line-height: 19.2px;
	font-weight: 400;
	padding: 0px 100px;
	background: $primary-background-gradient;
}

.footer-text-container {
	width: 500px;
	margin: auto;
	flex-basis: 20%;
}

.footer-text-container-2 {
	@include headerFooter();

	display: flex;
	align-items: center;
	margin: auto;
	padding-bottom: 20px;
	font-size: 16px;
	font-weight: 400;
}

.footer-text-container a {
	color: rgba(255, 255, 255, 1);
	;
	font-weight: 400;
}

.footer-tablet {
	height: 142px;
	flex-direction: column;
	padding: 32px 16px;
}

.footer-tablet .footer-text-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-bottom: 16px;
	p {
		font-size: 14px;
		line-height: 19px;
	}
}

.footer-mobile {
	height: 209px;
	padding: 32px 16px;
}

.footer-mobile .footer-text-container {
	flex-direction: column;
	width: 280px;
	margin-bottom: 16px;
	p {
		font-size: 14px;
		line-height: 19px;
	}
}