@import 'src/assets/sass/variables';
@import 'src/assets/sass/mixins';

.FAQ-container-desktop {
	margin-top: 450px;
}

.FAQ-container-tablet,
.FAQ-container-mobile {
	margin-top: 200px;
}

.faq-link:hover {
	color: black;
}

.faq-title {
	font-style: normal;
	font-weight: 700;
	display: flex;
	align-items: center;
	flex-direction: row;
	color: $color-slalom-primary;
	margin: 64px 0px;
}

.faq-header {
	@include cardTitle();
	padding: 16px 0px;
	margin: 64px 0px 16px 0px;
	text-transform: none;
	z-index: 99;

	p {
		text-transform: uppercase;
	}
}

.faq-header-large {
	font-size: 18px;
	line-height: 22px;
	z-index: 99;
}

.faq-header-mobile {
	font-size: 18px;
	line-height: 22px;
	margin: 32px 0px 16px 0px;
	width: 10%;
	z-index: 99;
}

// .faq-page {
// 	.page-wrapper-content {
// 		background-color: $color-purple;
// 	}
	
// 	.page-wrapper-content-mobile {
// 		margin-top: 80px;
// 	}
// }
