@import 'src/assets/sass/variables.scss';

.sponsor-partner-desktop-component {
	width: 428px;
	height: 200px;
	border-radius: 20px;
	margin-bottom: 18px;
	margin-right: 16px;
	display: flex;
	cursor: pointer;
	background-color: white;
	border: 2px solid $color-black;
	overflow: hidden;
	justify-content: center;
	align-items: center;
}

.sponsor-tile-container {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	margin-bottom: 16px;
}

.sponsor-tile-image-container {
	height: 200px;
	background-repeat: no-repeat;
	background-position: center;
	border: 2px solid $color-white;
	border-width: 2px 2px 0 2px;
	cursor: pointer;
	background-color: $color-white;
	border-radius: 16px;
	background-size: contain;
}

.sponsor-tile-image-desktop, .sponsor-tile-image-tablet {
	width: 300px;
	height: 215px;
}

.sponsor-tile-image-mobile {
	width: 223px;
	height: 215px;
}

.sponsor-tile-info-container {
	width: 100%;
	padding: 0 8px;
	height: 235px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
	background: linear-gradient(0deg, #eee9ff, #eee9ff);
	backdrop-filter: blur(21px);
	border: 2px solid $color-black;
	border-width: 2px 2px 2px 2px;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.sponsor-name-container {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	color: $color-black;
	margin-top: 20px;
}

.sponsor-blurb-container {
	font-size: 14px;
	line-height: 20px;
	font-weight: 400, regular;
	color: $color-black;
	margin-top: 10px;
	margin-bottom: 20px;
}

@media screen and (max-width: 1182px) {
	.sponsor-tile-margin {
		margin-right: 16px;
	}
}
