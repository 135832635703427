@import "src/assets/sass/variables.scss";

.scroll-to-top-button {
  padding: 0 0 2rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
}

.scroll-to-top-icon {
  width: 50px;
  height: 50px;
  cursor: pointer;
  background: $avatar-background-gradient;
  backdrop-filter: blur(20px);
  border-radius: 50%;
}