@import 'src/assets/sass/mixins';

#gameday-winners-section .section-title {
	justify-content: flex-start;
}

.leaderboard-title {
text-align: left;
padding-left: 0
}

.winners-page {
	max-width: 1320px;

	@media screen and (max-width: 1190px) {
		width: 100%;
	}
}

.page-wrapper {
	&__content-container {
		display: flex;
		justify-content: center;

	}
}

#winners-note-section.flex-container {
	justify-content: space-between;
}

#winners-note-section .app-card {
	width: 100%;
}

#winners-note-section .app-card-content {
	justify-content: flex-start;
}

#winners-info-section .app-card {
	width: 32%;
}

#winners-info-section .app-card-content {
	height: 300px;
	justify-content: flex-start;
	@media screen and (max-width: 1182px) {
		height: 350px;
	}
}

#winners-info-section .app-card-content p {
	height: 150px;
	@media screen and (max-width: 1182px) {
		height: 175px;
	}
}

#winners-info-section .app-button {
	width: 200px;
	margin: 0 auto;
}

#winners-info-section.flex-container {
	justify-content: space-between;
}

#gameday-winners-section div.leaders:last-child {
	margin-bottom: 0;
}

#gameday-winners {
	display: flex;

	@media screen and (max-width: 1182px) {
		overflow-x: auto;
	}

	.top-leader-card {
		&:not(:last-child) {
			margin-right: 32px;
		}

		@media screen and (max-width: 1182px) {
			max-width: 375px;
			min-width: 300px;
		}
		@media screen and (max-width: 980px) {
			max-width: 250px;
			min-width: 250px;
		}
	}
}

@media screen and (max-width: 1400px) and (min-width: 1183px) {
	#winners-note-section .app-card-content {
		height: 250px;
	}
}

@media screen and (max-width: 1268px) and (min-width: 1183px) {
	#winners-note-section .app-card-title {
		height: 75px;
	}
}

@media screen and (max-width: 1182px) {
	.winners-page {
		padding-top: 32px;
	}

	#winners-note-section.flex-container {
		flex-direction: column;
	}

	#winners-note-section .app-card {
		width: 100%;
	}

	#winners-note-section .app-card-content {
		min-height: min-content;
		max-height: max-content;
		height: initial;
	}

	#winners-note-section .app-card:not(:last-child) {
		margin-bottom: 64px;
	}

	#winners-info-section {
		overflow-x: auto;

		.app-card {
			min-width: 300px;

			p {
				margin-bottom: 0;
			}

			h2 {
				height: 120px;
				margin-bottom: 0;
			}

			&:not(:last-child) {
				margin-right: 1.5rem;
			}
		}
	}
}

.contact-section {
	&-title {
		min-height: 80px;
	}
	&-text-body {
		height: 100px;
	}
}