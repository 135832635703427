@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.quiz-description {
	margin-bottom: 32px;

    .app-card-content {
        @include card();
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
		padding: 16px;


		p {
			flex-grow: 2;
			padding: 16px;
		}

		@media screen and (max-width: 1440px) {
			flex-direction: column;
		}
		@media screen and (max-width: 768px) {
			align-items: flex-start;
		}
	}
}

.quiz-description-container {
	display: flex;
	align-items: center;
	border-radius: 16px;
	font-family: SlalomSans;
	color: $color-white;
}

.quiz-description-container.desktop {
	background: none;
	flex-direction: row;
}

.quiz-description-container.tablet {
	background: none;
	flex-direction: column;
}

.quiz-description-container.mobile {
	background: none;
	flex-direction: column;
}

.quiz-description-container p {
	font-size: 16px;
	padding-right: 32px;
}

.points-container {
	color: $color-white;
	display: flex;
	padding: 16px;
	align-items: center;
	font-weight: bold;
	min-width: 222px;
	justify-content: normal;
}

.database-icon {
	padding-right: 16px;
}

.quiz-content {
	.app-card-content {
		padding:16px;
		gap:16px;
	}

	&__title {
		@include cardTitle();
		font-size: 18px;
		margin-bottom: 16px;
		line-height: 26px;
		@media screen and (max-width: 1440px) {
			text-align: center;
		}
		@media screen and (max-width: 768px) {
			text-align: start;
		}
	}
	&__section{
		padding: 32px 16px 16px;		
	}
}

.quiz-content-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	border-radius: 16px;
	font-family: SlalomSans;
	color: $color-white;
}

.quiz-question {
	font-size: 16px;
	line-height: 20px;
}

.quiz-icon-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.quiz-answers-container {
	padding: 0px 16px 16px;
	display:flex;
	flex-direction: column;
	align-items: stretch;
}

.quiz-answers-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 16px;

	@media screen and (max-width: 1440px) {
		flex-wrap: wrap;
	}
}

.quiz-button {
	@include fontStandard();
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	border-radius: 50px;
	padding: 19px 24px;
	background: rgba(255, 255, 255, 0.15);
	border: 1px solid $color-light-gray;
	max-width: 300px;
	flex-grow: 1;
	cursor: pointer;
	gap:29px;

	@media screen and (max-width: 1440px) {
		max-width: none;
		flex-basis: 45%;
	}

	@media screen and (max-width: 768px) {
		max-width: none;
		flex-basis: 100%;
	}


	&:last-child {
		margin-right: 0;
	}

	&:hover {
		box-shadow: 0px 1px 10px 0px $color-cyan-light-1;
		border-color: $color-cyan-light-1;

		.quiz-button-circle {
			box-shadow: 0px 1px 10px 0px $color-cyan-light-1;
			border-color: $color-cyan-light-1;
		}
	}

	&-circle {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		border: 1px solid $color-light-gray;
		height: 25px;
		width: 25px;
		min-width: 25px;
		border-radius: 50%;

		&__inner {
			width: 15px;
			height: 15px;
			background-color: transparent;
			border-radius: 50%;
		}
	}

	&-answer {
		flex-grow: 1;
		text-align: left;
		overflow-wrap: anywhere;
	}

	&-selected {
		font-weight: 400;
		border-color: $color-cyan-light-1;
		background: $primary-background-gradient;

		.quiz-button-circle {
			background: none;
			border: 1px solid rgba(255, 255, 255, 0.2);

			&__inner {
				background-color: $color-cyan-light-2;
			}
		}
	}

	&-correct {
		box-shadow: none;
		background-color: rgba(77, 255, 116, 0.5);
		border: none;
		cursor: initial;
		color: black;
		font-weight: 400;

		&:hover {
			box-shadow: none;
	
			.quiz-button-circle {
				box-shadow: none;
				border-color: $color-correct-green;
			}
		}

		.quiz-button-circle {
			border: 1px solid $color-correct-green;

			&__inner {
				background-color:  $color-correct-green;
				&:hover {
					box-shadow: none;
				}
			}
		}
	}

	&-wrong {
		box-shadow: none;
		background-color: rgba(255, 77, 77, 0.72);
		border: none;
		cursor: initial;
		color: black;
		font-weight: 400;

		&:hover {
			box-shadow: none;

			.quiz-button-circle {
				box-shadow: none;
				border-color: $color-wrong-red;
			}
		}

		.quiz-button-circle {
			border: 1px solid $color-wrong-red;

			&__inner {
				background-color: $color-wrong-red;
				&:hover {
					box-shadow: none;
				}
			}
		}

	}
}

.quiz-button.desktop {
	background: none;
	width: 23%;
}

.quiz-button.tablet {
	background: none;
	width: 48%;
}

.quiz-button.mobile {
	background: none;
	width: 98%;
}

.quiz-button-description {
	display: inline-block;
	padding: 10px 75px 10px 25px;
}

.quiz-submission {
	&-container {
		padding: 0 16px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		justify-items: center;

		&.desktop {
			background: none;
			justify-content: flex-end;
		}

		&.tablet {
			background: none;
		}

		&.mobile {
			background: none;
		}
	}

	&-content {
		color: $color-gray;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap:  8px;

		.app-button-generic-container {
			margin-bottom: 8px;
			width: 100%;
			display: flex;
			justify-content: center;
		}

		p {
			font-size: 14px !important;
		}

		@media screen and (max-width: 320px) {
			max-width: 130px;
			p {
				text-align: center;
			}
		}
	}
}

.correct-explanation {
	font-size: bold;
	color: white;
}

.wrong-explanation {
	font-size: bold;
	color: white;
	margin-bottom: 16px;
}

.section-qotd {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;
}

.quiz-of-the-day {
	background: none;
}

.overlay {
	position: absolute;
	z-index: 2;
}
