@import 'src/assets/sass/variables.scss';

.wrap-page-bg-container {
	background-image: url('../../assets/images/end-announcement.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	overflow-y: hidden;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.wrap-page-desktop {
	align-items: center;

	img {
		top: 2%;
		position: relative;
	}

    .footer-container {
		position: absolute;
		bottom: 0;
		width: 100%;
		footer {
			justify-content: center;
			width: 100%;
		}
	}
}

.wrap-page-tablet {
	img {
		top: 2%;
		position: relative;
		width: 100%
	}

	.footer-container {
		footer {
			position: absolute;
			bottom: 0;
			justify-content: center;
			width: 100%;
		}
	}
}

.wrap-page-mobile {
	.nav-logo-mobile {
		top: 2px;
	}

	img {
		top: 2%;
		position: relative;
		width: 100%
	}

	
	.footer-container {
		footer {
			position: absolute;
			bottom: 0;
			justify-content: center;
			width: 100%;
			height: 160px;
			align-items: center;
				
			&:first-child {
				padding-top: 50px;
			}
		}
	}
}

.wrap-page.tablet {
	margin-bottom: -140px;
}

.wrap-page.mobile {
	justify-content: flex-start;
}

.wrap-page-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: $color-white;
	border-radius: 16px;

	img {
		margin-top: 18%;
	}
}

.wrapup-title {
	font-family: Slalom Sans;
	font-weight: 700;
	font-style: normal;
	font-size: 38px;
	line-height: 46px;
	text-align: center;
}

.wrapup-header-desktop {
	position: absolute;
	top: 25%;
    width: 580px;
	font-family: Slalom Sans;
	font-weight: 700;
	font-style: normal;
	font-size: 38px;
	line-height: 46px;
	text-align: center;
	margin-bottom: 24px;
	color: $color-white;
}

.wrapup-header-tablet {
	font-family: Slalom Sans;
	font-weight: 700;
	font-style: normal;
	font-size: 38px;
	line-height: 46px;
	margin-bottom: 24px;
	margin-left: 54px;
	margin-right: 54px;
	color: $color-white;
	word-wrap: break-word;
	text-align: center;
	width: 520px;
	align-self: center;
	margin-top: -100px;
}

.wrapup-header-mobile {
	top: -5%;
	position: relative;
	font-family: Slalom Sans;
	font-weight: 700;
	font-style: normal;
	font-size: 32px;
	line-height: 38px;
	text-align: center;
	padding: 0 60px;
	color: $color-white;
}

.wrapup-content-desktop {
	position: absolute;
    width: 580px;
	white-space: pre-line;
	font-family: Slalom Sans;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    justify-content: left;
	color: $color-white;
}

.wrapup-content-tablet {
	font-family: Slalom Sans;
	font-weight: 400;
	font-style: normal;
	font-size: 16px;
	line-height: 19px;
	padding: 0 40px;
	margin-bottom: 36px;
	display: flex;
	justify-content: center;
	color: $color-white;
	margin-left: 54px;
	margin-right: 54px;
	width: 650px;
	align-self: center;
	white-space: pre-line;
}

.wrapup-content-mobile {
	position: relative;
	top: -3%;
	font-family: Slalom Sans;
	font-weight: 400;
	font-style: normal;
	padding: 0 80px;
	margin-bottom: 32px;
	text-align: left;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	justify-content: center;
	color: $color-white;
	white-space: pre-line;
}

.wrapup-button-container-desktop {
	display: flex;
	flex-direction: column;
	top: 70%;
	position: absolute;
	width: 580px;

	.top-buttons {
		margin-left: auto;
		margin-right: auto;
		gap: 20px 15px;
		text-align: -webkit-center;

		.app-button-generic-container {		
			.app-button {
				width: auto;
				background: #FFFFFF;
				border-radius: 50px;
				padding-left: 20px;
    			padding-right: 20px;
			}
		}
	}
}

.wrapup-button-container-tablet {
	display: flex;
	flex-direction: row;
	position: absolute;
	width: 580px;
	align-self: center;
	top: 65%;

	.top-buttons-tablet {
		margin: 0 auto;
		text-align: -webkit-center;
		max-width: unset;
		flex-direction: row;
		grid-gap: 10px;

		.app-button-generic-container {
			margin-bottom: 20px;

			.app-button {
				width: 100%;
				padding: 0px;
			}

			.app-button-primary {
				width: fit-content;
				padding: inherit;

			}
		}
	}
}


.wrapup-button-container-mobile {
	top: -3%;
    position: relative;
	.top-buttons {
		align-items: center;
		flex-direction: column;
		width: auto;

		.app-button {
			width: auto;
		}
		.app-button-primary {
			height: unset;
			padding: 10px;
		}
	}
}

.wrapup-page-container-mobile {
	display: flex;
	flex-direction: column;
	overflow: auto;
	
	.top-buttons-tablet {
		flex-wrap: wrap;
		margin-left: auto;
		margin-right: auto;
		gap: 20px 0px;
		text-align: -webkit-center;
		max-width: unset;

		.app-button-generic-container {
			white-space: nowrap;

			.app-button {
				width: auto;
			}
		}
	}
}

.wrap-page-header {
	font-weight: 700;
	text-align: center;
	color: #ffffff;
	font-size: 38px;
	line-height: 46px;
	margin: 0px 0px 32px 0px;
	padding: 0px 10px 0px 0px;
}

.wrap-page-header.mobile {
	margin: 48px 0px 32px 0px;
	padding-top: 150px;
	font-size: 32px;
	line-height: 38px;
}

.wrap-page-text {
	margin: 0px 0px 48px 0px;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;
}

.wrap-page-text.desktop {
	padding: 0px 10px 0px 10px;
}

.wrap-button {
	width: 100%;
	margin: 12px 0;
	align-items: center;
	justify-content: center;
}

.wrap-button-flex {
	display: flex;
	gap: 20px;
}

.wrap-button-flex.mobile {
	flex-direction: column;
	align-items: center;
}

.top-buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 20px;
	margin-bottom: 36px;
	margin-right: 24px;
	margin-left: 24px;
}

.top-buttons-tablet {
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-content: center;
	max-width: 60%;
	margin: auto;
	margin-bottom: 36px;
}

.wrap-page-bg-container {
	background-color: $color-light-blue;
	height: 100%;
}
