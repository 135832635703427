@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

/* Dropdown Styles */
.dropdown-label {
	color: $color-white;
}

.dropdown-container {
	width: unset !important;
	display: flex;
}

.ui.multiple.dropdown {
	position: relative;
	border-radius: 1px;
	width: 175px;
	height: 75px;
	background-color: $color-dark-purple;
	border: 1px solid rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(40px);
	border-radius: 25px;
	z-index: -1;
	overflow: visible !important;
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
	font-family: Slalom Sans;
	font-style: normal;
	font-weight: 900;
	font-size: 16px;
	line-height: 20px;
	color: $color-white;
	z-index: 10;
}

.ui.dropdown .menu > .item:hover {
	color: $color-cyan-light-1 !important;
	text-shadow: 0px 0px 15px $text-shadow;
	font-weight: 700;
}

.ui.dropdown .menu > .item {
	background-color: $color-dark-purple;
	color: $color-white !important;
}

.ui.dropdown .menu > .item:hover.active {
	background-color: $color-white;
	color: $color-white;
}

.ui.dropdown .menu > .item {
	height: 50px;
	border-top: unset !important;
	color: $color-dark-purple;
}

a.ui.active.label:hover,
a.ui.labels .active.label:hover {
	background: $color-white;
	opacity: 0.1;
}

.ui.selection.dropdown,
.ui.selection.active.dropdown,
.ui.active.visible.selection.dropdown {
	background-color: $color-dark-purple;
	border-color: $primary-border;
}

.ui.selection.active.dropdown .menu {
	background: $color-dark-purple;
	backdrop-filter: blur(20px);
	box-sizing: border-box;
	border-radius: 0px 0px 20px 20px;
	border-color: transparent;
	opacity: 1;
	min-height: auto;
	max-height: 350px;
}

// .ui.dropdown > .text,
.ui.selection.visible.dropdown > .text:not(.default),
.ui.selection.active.dropdown,
.ui.selection.dropdown:focus,
.ui.selection.dropdown:hover,
.ui.selection.active.dropdown:hover,
.ui.dropdown.active.label:hover,
.ui.selection.active.dropdown:hover .menu {
	border-color: transparent;
	color: $color-cyan-light-1;
	font-weight: 700;
}

.ui.dropdown > .text,
.ui.dropdown .menu > .item.selected {
	color: $color-white !important;
	font-weight: 700;
}


.ui.selection.dropdown .menu > .item {
	font-size: 16px;
	padding: 1rem 1rem !important;
}

.ui.selection.dropdown .menu > .item:nth-last-child(n+2)::before {
	content: '';
	position: absolute;
	height: 1px;
	bottom: 0;
	background-color: $color-white;
	width: 90%;
}

.ui.selection.dropdown {
	width: 100%;
	height: 100%;
	background: $color-dark-purple;
	border: 1px solid $primary-border;
	backdrop-filter: blur(40px);
	border-radius: 25px;
	z-index: 0;
}

.ui.selection.dropdown:focus {
	backdrop-filter: blur(40px);
	border-radius: 25px;
}

.ui.dropdown > .dropdown.icon:before {
	content: '';
	pointer-events: all;
}

.ui.active.selection.dropdown > .dropdown.icon,
.ui.visible.selection.dropdown > .dropdown.icon {
	background-image: url('../../assets/images/up-arrow.svg');
}

.ui.dropdown > .dropdown.icon {
	background-image: url('../../assets/images/down-arrow.svg');
	background-size: auto;
	background-repeat: no-repeat;
	top: 2.2rem !important;
	right: 1.3rem !important;
}

.ui.multiple.dropdown > .label {
	margin: 3px 0px 3px 5px;
	height: 40px;
	padding: 10px;
	text-align: left;
	border-radius: 25px;
	background: $color-white;
	border: none;
	font-size: 16px;
	line-height: 19px;
	color: $color-dark-purple;

	white-space: nowrap;
}

a.ui.active.label:hover,
a.ui.labels .active.label:hover {
	opacity: 0.7;
}

i.icon.delete:before {
	content: '';
	pointer-events: all;
}

.ui.label > .close.icon,
.ui.label > .delete.icon {
	background-image: url('../../assets/images/tooltip_close.svg');
	background-size: contain;
	background-repeat: no-repeat;
	width: 11px;
	height: 11px;
	margin-right: 0;
	margin-left: 1rem;
	font-size: unset;
	opacity: unset;
}
