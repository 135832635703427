@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.app-card {
	width: 100%;
	@include fontStandard();
	margin-bottom: 16px;
	display: flex;
	flex-direction: column;
	color: $color-white;
	border: $primary-border;
	border-radius: 20px;
}

.app-card-title {
	font-size: 32px;
	text-transform: none;
	color: $color-white;
}

.app-card-content {
	@include card();
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: flex-start;
	flex-grow: 1;
	width: 100%;
	padding: 32px;
	padding: $length-app-card-content-padding;
}

.app-card-header {
	@include sectionHeader();
}

@media screen and (max-width: 1182px) {
	.app-card-title {
		font-size: 18px;
	}
}
