$box-shadow: -5px 5px 0px 0px #000;

// ----------- CALCUATE OVERFLOW STYLING -----------
$length-app-card-content-padding: 32px;
$length-content-container-mobile-padding: 32px;
$length-content-container-tablet-padding: 20px;

// ----------- NEW COLORS FOR PRIDE ------------
$color-dark-purple: #52024a;
$color-purple: #8b3d83;
$color-light-purple: #c094bc;
$color-dark-red: #c3002f;
$color-red: #b94b65;
$color-light-red: #c999a5;
$color-dark-blue: #66a3ff;
$color-blue: #adceff;
$color-light-blue: #dae9ff;
$color-darkest-gray: #858585;
$color-dark-gray: #b3b5bd;
$color-gray: #d2d4da;
$color-light-gray: #f3f4f8;
$color-cyan: #1be1f2;
$color-cyan-light-1: #5feaf6;
$color-cyan-light-2: #8df0f9;
$color-coral-light-3: #ffdbdf;
$color-dark-blue-2: #121C49;
$primary-background-gradient: linear-gradient(91.03deg, rgba(0, 0, 0, 0.376) 2.56%, rgba(56, 56, 56, 0.344) 51.31%, rgba(0, 0, 0, 0.432) 103.21%);
$secondary-background-gradient: linear-gradient(90.6deg, rgba(255, 255, 255, 0.15) 20.99%, rgba(255, 255, 255, 0.075) 50%, rgba(255, 255, 255, 0.15) 80.25%);
$avatar-background-gradient: linear-gradient(.25turn, rgba(255, 255, 255, 0.42), rgba(255, 255, 255, 0.06));
$primary-border: 1px solid rgba(255, 255, 255, 0.3);
$dropdown-background: rgba(255, 255, 255, 0.15);
$text-shadow: 0px 0px 15px rgba(27, 225, 242, 0.8);

// ----------- NEW COLORS -----------
$color-slalom-blue: #0c62fb;
$color-cyan: #1be1f2;
$color-wrong-red: #ff0000;
$color-correct-green: #0b9d59;
$color-coral-red: #ff4d5f;
$color-light-red: #ff828f;
$color-chartreuse: #deff4d;
$color-chartruese-dark: #a4c800;
$color-light-black: #343434;
$color-bright-green: #bdffdd;
$color-light-coral-red: #ffc6ce;
$color-cream: #fbffed;

// ----------- OLD COLORS TO KEEP BUILD SUCCESSFUL TEMPORARILY -----------
$color-slalom-primary: rgba(222, 255, 77, 1);
$color-bg-light: #ebebeb;
$color-bg-border: #9c9c9c;

$color-black: #000;
$color-white: #fff;
$color-app-button-primary-hover: #5e6072;
$color-lilac: #eee9ff;

$color-yellow: $color-chartreuse;
$color-red: $color-coral-red;

$transparent-light-purple: rgba(199, 185, 255, 0.2);

$card-shadow-color: rgba(222, 255, 77, 0.6);
$card-shadow: 0px 0px 40px 0px $card-shadow-color;
$text-shadow: 0px 0px 10px $color-light-purple;

$card-bg: $color-cream;
