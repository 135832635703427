.about-partners-desktop {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 20px;
	padding-bottom: 30px;
	justify-content: center;
}

.about-partners-tablet{
	display: flex;
	flex-direction: row;
	overflow: auto;
	flex-wrap: nowrap;
	padding-top: 5%;
	padding-left: 30px;
}

.about-partners-mobile {
	display: flex;
	flex-direction: row;
	overflow: auto;
	flex-wrap: nowrap;
	padding-bottom: 30px;
}