.page-banner {
	&-img {
		&__background {
			object-fit: cover;
		}
	}
}

.page-banner-title {
	position: absolute;
	left: 0;
}

.page-banner-title-desktop {
	bottom: 48px;
	width: 450px;
	left: 60px;
}

.page-banner-title-tablet {
	bottom: 48px;
	width: 300px;
	left: 40px;
}

.page-banner-title-mobile {
	bottom: 32px;
	left: 17.5%;
	width: 65%;
}
