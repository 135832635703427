@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.donate-description {
    margin-bottom: unset;
    .app-card-content {
        @include card();
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;


		@media screen and (max-width: 705px) {
			flex-direction: column;
		}
	}
}

.donate-section-container-desktop {
    .section-donate {
        .section-title {
            justify-content: left;
            line-height: 38px;
        }
    }
    .app-card-content {
        padding: 20px;
        .donate-container {
            padding-right: 32px;
            p {
                font-weight: 700;
                line-height: 19px;
                white-space: nowrap;
                padding-top: 10px;
            }
            img {
                padding-left: 10px;
            }
            .hearthandshake-icon {
                padding-right: 16px;
            }
        } 
        .donate-text-container { 
            align-self: baseline;
            p {
                font-weight: 400;
                line-height: 19px;
                padding-right: 32px;
                padding-top: 5px;
            }
        }
        .app-button-container {
            padding-right: 16px;
            .app-button {
                width: 98px;
                height: 47px;
            }
        }  
    }
}

.donate-section-container-mobile {
    .section-donate {
        padding: 5px;
        .section-title {
            font-size: 18px;
            line-height: 22px;
            text-transform: none;
            justify-content: left;
        }
    }
    .app-card-content {
        grid-gap: 32px;
        .donate-container {
            flex-direction: column;
            align-items: center;
            p {
                font-weight: 700;
                line-height: 19px;
                white-space: nowrap; 
            }
        }
        .donate-text-container {
            p {
                line-height: 19px;
                font-weight: 400;
            }
        }
        .app-button-container {
            align-self: center;
            .app-button {
                width: 98px;
                height: 47px;
            }
        }  
    }
}

.donate-section-container-tablet {
    .section-donate {
        .section-title {
            font-size: 18px;
            line-height: 22px;
            text-transform: none;
            justify-content: left;
        }
    }
    .app-card-content {
        padding: 20px;
        grid-gap: 32px;
        .donate-container {
            padding-right: 32px;
            flex-direction: column;
            gap: 8px;
            align-items: center;
            p {
                font-weight: 700;
                line-height: 19px;
                white-space: nowrap;
            }
            img {
                padding-left: 25px;;
            }
            .hearthandshake-icon {
                padding-right: 16px;
                padding-left: 32px;
            }
        }   
        .donate-text-container {
            p {
                font-weight: 400;
                line-height: 19px;
                padding-right: 32px;
            }
        }
        .app-button-container {
            align-self: baseline;
            padding-right: 16px;
            .app-button {
                width: 98px;
                height: 47px;
            }
        }  
    }
}

.donate-container {
	color: $color-white;
	display: flex;
	font-weight: bold;
}