@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.welcome-modal {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba($color: $color-black, $alpha: 0.5);
  transition: 0.3s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.welcome-modal-container-desktop {
  width: 60%;
  margin: 5% auto;
  background-color: $color-dark-purple;
	padding: 24px;
	border-radius: 20px;
	border: 1px solid $color-black;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  padding: 32px;
}

.welcome-modal-container-tablet {
  margin: 5% auto;
  background-color: $color-dark-purple;
	padding: 24px;
	border-radius: 20px;
	border: 1px solid $color-black;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  width: 80%;
  padding: 32px;
}


.welcome-modal-container-mobile {
  width: 100%;
  margin: 5% auto;
  background-color: $color-dark-purple;
	border-radius: 20px;
	border: 1px solid $color-black;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  padding: 32px;
}

.welcome-title-desktop,
.welcome-title-tablet {
	@include sectionHeader();
  display: block;
	z-index: 1;
  font-size: 70px;
  width: 100%;
  color: $color-cyan-light-2;
  text-align: center;
  line-height: 70px;
}

.welcome-title-mobile {
	@include sectionHeader();
  display: inline-block;
	z-index: 1;
  width: 100%;  
  font-size: 64px;
  color: $color-cyan-light-2;
  text-align: center;
  line-height: 70px;
}

.card-container {
  background: rgba(90,2,81, 100%);
  border-radius: 20px;
}

.welcome-modal-body {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.welcome-modal-section {
  display: flex;
  gap: 32px;

  &__image {
    margin: auto 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__title {
      font-weight: 700;
      text-shadow: 0px 0px 10px #C094BC;
      font-size: 18px;
    }

    &__body {
      font-weight: 400;
      font-size: 16px;
    }
  }
}

.app-button-welcome {
  @include buttonPrimary();
  height: 61px;
  width: 200px;
  cursor: pointer;
	text-transform: uppercase;
}