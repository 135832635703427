@import 'src/assets/sass/variables.scss';

.tooltip-container {
	position: relative;
	display: flex;
	height: auto;
	align-items: center;
	margin: 32px 16px;
}

.tooltip-content {
	position: absolute;
	width: 226px;
	z-index: 10;
	padding: 0 16px 16px 16px;
	line-height: 19px;
	color: $color-dark-purple;
	background: $color-white;
	border-radius: 16px;
	top:0;
	left: - 20.5px;
	transform: translate(-100%,0%);

	@media screen and (max-width: 320px) {
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		margin-left: 0px;
		
	}
}
div.tooltip-close ~ * {
	padding-right: 16px;
}

.tooltip-close {
	text-align: right;
	cursor: pointer;
	padding-top: 16px;
}

.tooltip-icon {
	cursor: pointer;
	border-radius: 20px;
	position: relative;
	margin-left: 16px;
	margin-right: 40px;
	
	&__circle {
		position: absolute;
		height: 35px;
		width: 35px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@media screen and (max-width: 768px) {
			height: 25px;
			width: 25px;
		}
	}
	&__text {
		z-index: 2;
		height: 17px;
		width: 5px;		
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@media screen and (max-width: 768px) {
			height: 12px;
			width: 3px;
		}
	}
}