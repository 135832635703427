@import 'src/assets/sass/variables';
@import 'src/assets/sass/mixins';

.section-container {
	width: 100%;
	color: $color-black;
}

.section-title-container {
	display: flex;
	margin: auto;
	height: 2.4em;
	justify-content: space-between;
	margin-bottom: 50px;
	text-transform: none;
	color: $color-black;
}

.other-ways-to-earn-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	height: 100%;
	justify-content: space-between;

	.app-card {
		width: 32.5%;
		color: $color-black;

		h2 {
			height: 60px;
			color: $color-black;
		}

		.points-value {
			margin-left: 16px;
			font-weight: 400;
			color: $color-white;
			font-weight: bold;
		}

		.tile-description {
			color: $color-white;
			
			a {
				color: $color-white;
			}
		}

		.tile-button-container {
			margin-left: inherit;
		}

		.app-button-primary {
			@include longButtonTexts;
		}

		.app-card-content {
			padding: 10px;
		}
	}
}

.home-page-container {

	::-webkit-scrollbar-thumb {
		border: 4px solid #000000;
	}
}

.other-ways-to-earn-page {
	.section-title {
		justify-content: flex-start;
		text-align: left;
	}
	@media screen and (max-width: 1182px) {
		.section-title {
			font-size: 18px;
			line-height: 18px;
		}
	}
}

.other-ways-to-earn-tile {
	background: radial-gradient(
		100% 16109.47% at 0% 0%,
		rgba(255, 255, 255, 0.42) 0%,
		rgba(255, 255, 255, 0.06) 100%
	);
	backdrop-filter: blur(40px);
	border-radius: 16px;
	padding: 16px;
	margin: 0 0 32px 0;
	width: 32%;
	height: 400px;
}

.other-ways-to-earn-tile-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	height: 100%;
	justify-content: space-between;
}

.tile-title {
	padding: 16px;
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	display: flex;
	align-items: center;
	color: $color-white;
}

.points-icon {
	margin: 0 6px 0 16px;
	width: 40px;
	height: 40px;
	color: $color-black;
}

.points-value {
	font-size: 16px;
	display: flex;
	align-items: center;
}

.tile-points {
	display: flex;
	margin-left: 16px;
}

.tile-description {
	padding: 16px;
	font-family: 'Slalom Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	height: fit-content;
	color: $color-dark-purple;
}

.tile-button-container {
	margin: auto auto 32px auto;
	padding: 0 16px;
	padding-top: 10px;
}

@media screen and (max-width: 960px) {
	.other-ways-to-earn-container {
		display: flex;
		flex-direction: row;
		overflow-x: auto;
		width: 100%;
		flex-wrap: nowrap;
		height: 100%;
		@include extend-overflow-right($length-content-container-tablet-padding);

		.app-card {
			min-width: 320px;
			max-width: 350px;

			&:not(:last-child) {
				margin-right: 20px;
			}
		}
	}

	.tile-title {
		padding: 16px;
		font-family: 'Slalom Sans';
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		display: flex;
		align-items: center;
		color: $color-white;
	}

	.tile-button {
		width: 100%;
		margin: 16px 16px 16px 0;
		padding: 0 16px 0 16px;
	}
	.tile-description {
		height: 200px;
		color: $color-white;
	}
}

@media screen and (max-width: 705px) {
	.other-ways-to-earn-container {
		display: flex;
		flex-direction: row;
		overflow: auto;
		width: 100%;
		flex-wrap: nowrap;
		height: 100%;

		@include extend-overflow-right($length-content-container-mobile-padding);
	
		.app-card {
			width: calc(100% + $length-app-card-content-padding);
			min-width: 280px;
		}
	}
}

.tile-tooltip {
	justify-content: space-between;
	position: absolute;
	top: 16px;
	right: 24px;
}
