@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.top-leader-card {
	font-family: 'Slalom Sans';
	display: flex;
	position: relative;
	border: $primary-border;
	border-radius: 20px;
	@include card();
}

.top-leader-content-desktop {
	border-radius: 20px 10px 10px 20px;
	padding: 10px 0;
	margin-left: 42px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 260px;
	z-index: 1;
}

.top-leader-content-tablet,
.top-leader-content-mobile {
	border-radius: 20px 10px 10px 20px;
	padding: 10px 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 260px;
	z-index: 1;
}

.top-leader-avatar {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.top-leader-stat-h2 {
	margin: 0;
	font-size: 24px;
	font-weight: 700;
}

.top-leader-stat-h3 {
	margin: 0;
	font-size: 16px;
	font-weight: 400;
	padding-bottom: 15px;
}

.top-leader-stat-h4 {
	margin: 0;
}

.position-image {
	position: absolute;
	background-repeat: no-repeat;
	background-position: right;
	background-size: 360x 480x;
	top: 3px;
	right: 0;
	z-index: 0;
	width: 360px;
	height: 100%;
}

.position-0 {
	background-image: url('../../assets/images/crown-gold.svg');
	background-size: 310px 254px;
	border-radius: 25px;  
}

.position-1 {
	background-image: url('../../assets/images/crown-silver.svg');
	background-size: 310px 254px;
	border-radius: 25px;  
}

.position-2 {
	background-image: url('../../assets/images/crown-bronze.svg');
	background-size: 310px 254px;
	border-radius: 25px;  
}

@media screen and (min-width: 1388px) {
	.top-leader-card {
		height: 260px;
		min-width: 350px;
		max-width: 400px;
	}
}

@media screen and (min-width: 981px) {
	.top-leader-card {
		width: 32%;
	}
}

@media screen and (max-width: 1280px) {
	.top-leader-card {
		flex-direction: column;
		width: 31%;
		max-width: 310px;
		min-height: 301px;
		border-radius: 16px;
		overflow: hidden;
		justify-items: center;
	}

	.top-leader-content {
		border-radius: 16px 16px 8px 8px;
		align-items: center;
		padding-top: 40px;
	}

	.top-leader-rank {
		width: 40px;
		height: 40px;
		right: 10px;
		top: 10px;
	}

	.top-leader-stat-h2 {
		font-size: 18px;
	}

	.position-image {
		position: relative;
		background-repeat: no-repeat;
		background-size: 200px 91px;

		// Center tilted image
		background-position: center 0;
		background-position-x: 57%;

		width: 100%;
		height: 91px;
	}

	.position-0 {
		background-image: url('../../assets/images/crown-gold-tablet.svg');
	}

	.position-1 {
		background-image: url('../../assets/images/crown-silver-tablet.svg');
	}

	.position-2 {
		background-image: url('../../assets/images/crown-bronze-tablet.svg');
	}
}

@media screen and (max-width: 705px) {
	.top-leader-card {
		max-width: 300px;
		min-width: 200px;
		margin-right: 16px;

		&:last-child {
			margin-right: 0;
		}
	}
}
