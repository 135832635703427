@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans-Regular.woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans-Black.woff');
	font-weight: bolder;
	font-style: normal;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans-BlackItalic.woff');
	font-weight: bolder;
	font-style: normal;
	color: black;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans-Italic.woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans-BoldItalic.woff');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans-Bold.woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans-Light.woff');
	font-weight: lighter;
	font-style: normal;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans-LightItalic.woff');
	font-weight: lighter;
	font-style: italic;
}

.standalone-page-wrapper {
	margin-top: -80px;
}
