@import './variables';

@mixin title-xl {
	font-family: Slalom Sans;
	font-size: 24px;
	font-weight: 700;
	line-height: 29px;
	letter-spacing: 0em;
	text-align: left;
	text-shadow: 0px 0px 10px #C094BC;
	flex: none;
	order: 0;
	flex-grow: 1;
}

@mixin title-sm {
	font-family: Slalom Sans;
	font-size: 18px;
	font-weight: 700;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
	text-shadow: 0px 0px 10px #C094BC;
	flex: none;
	order: 0;
	flex-grow: 1;
}

@mixin body {
	font-family: Slalom Sans;
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
}

@mixin cardSecondary {
	@include card();
	border: 2px solid $color-black;
	box-shadow: $card-shadow;
}

@mixin fontBase {
	color: $color-white;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	line-height: 20px;
}

@mixin fontStandard {
	@include fontBase();
	font-family: 'Slalom Sans';
}

@mixin fontSecondary {
	@include fontBase();
	font-family: 'Slalom Sans';
}

@mixin card {
	border-radius: 20px;
	color: $color-white;
	font-family: 'Slalom Sans';
	/* Glassmorphic Stroke */
	background: $secondary-background-gradient;
	background-blend-mode: overlay;
	/* Background Blur */
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */
	border-radius: 20px;

	p {
		@include fontStandard();
	}
}

@mixin cardTitle {
	@include fontSecondary();
	font-weight: 700;
	font-size: 24px;
	line-height: 31px;

	@media screen and (max-width: 1182px) {
		font-size: 18px;
		line-height: 23px;
	}
}

@mixin leaderboardCard {
	color: $color-black;
	box-shadow: $box-shadow;
	overflow: hidden;

	// Background properties
	background: url('../../assets/images/leaderboard-bg.svg'), #FFFFFF;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-clip: content-box;
	mix-blend-mode: normal;
	opacity: 0.95;
}

@mixin fontHeader {
	@include fontStandard();
	font-family: 'Slalom Sans';
	font-size: 64px;
	font-weight: 700;
	line-height: 76.8px;
}

@mixin headerFooter {
	background: rgba(238, 233, 255, 1);
	backdrop-filter: blur(20px);
}
@mixin footerColor {
	background: rgba(52, 52, 52, 1);
	backdrop-filter: blur(20px);
}

@mixin sectionHeader {
	@include fontHeader();
	margin: 32px 0px;
	display: flex;
	color: $color-white;
	// This creates a border around the text of 1px and has a drop shadow
	text-shadow: $text-shadow;
	text-transform: uppercase;
}

@mixin labelHighlighted {
	background-color: $color-cyan-light-1;
	padding: 10px;
	color: $color-black;
	font-weight: 700;
	line-height: 19px;
}

@mixin button {
	@include fontStandard();
	padding: 1rem;
	text-align: center;
	border: none;
	width: 100%;

	&:hover {
		cursor: pointer;
		color: $color-app-button-primary-hover;
	}

	&:disabled {
		background-color: $color-dark-gray;
		border: none;
		border-radius: 50px;
		color: $color-white;
		cursor: not-allowed;

		&:hover {
			cursor: not-allowed;
		}
	}
}

@mixin buttonPrimary {
	@include button();

	background-color: $color-white;
	color: $color-dark-purple;
	font-weight: bold;
	padding: 24px 36px;
	border-radius: 50px;

	&:active {
		box-shadow: none;
	}

	&:disabled {
		box-shadow: none;
	}
}

@mixin longButtonTexts {
	width: auto;
}

@mixin buttonSecondary {
	background: none;
	color: $color-white;
	font-weight: bold;
	padding: 14px 20px;
	gap: 10px;

	span {
		border-bottom: 1px solid transparent;
	}

	&:visited {
		text-decoration: none;
	}

	&:hover {
		text-decoration: none;
		color: $color-dark-purple;
	}

	&:active {
		span {
			border-bottom: 1px solid black;
		}
		color: $color-black;
		text-decoration: solid;
		font-weight: bolder;
	}

	&:disabled {
		color: $color-gray;
	}
}

@mixin extend-overflow-right($padding) {
	width: calc(100% + $padding);
	padding-right: calc($padding + 1vw);
	box-sizing: border-box;
}
