@import 'src/assets/sass/variables';
@import 'src/assets/sass/mixins';

.about-link:hover {
	color: white;
}

.about-container {
	max-width: 1320px;
	margin: auto;
	position: relative;
}

.about-page-text-container-desktop {
	background: $color-light-blue;
	backdrop-filter: blur(40px);
	/* Note: backdrop-filter has minimal browser support */
	border-radius: 20px;
	box-sizing: border-box;
	padding: 32px;
	flex-direction: column;
	min-height: 300px;
}

.about-page-text-container-tablet,
.about-page-text-container-mobile {
	background-color: $color-bg-light;
	backdrop-filter: blur(40px);
	border-radius: 20px;
	box-sizing: border-box;
	padding: 32px;
	flex-direction: column;
}

.about-page-text-body-container {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	margin: 0;
	display: flex;
	flex-direction: column;
	justify-content: left;
	height: 160px;
}

.about-header {
	@include sectionHeader();
	justify-content: center;
	font-size: 92px;
	line-height: 92px;
	font-weight: 900;
}

.about-header-tablet {
	justify-content: center;
	font-size: 500%;
	line-height: 92px;
	font-weight: 700;
	margin-top: 48px;
	margin-bottom: 32px;
}
.about-header-mobile {
	justify-content: center;
	font-size: 250%;
	line-height: 92px;
	font-weight: 700;
	margin-top: 48px;
	margin-bottom: 32px;
}

.about-title {
	font-style: normal;
	font-weight: 700;
	text-align: left;
	margin-top: 32px;
	margin-bottom: 32px;
	text-transform: none;
	color: rgba(0, 0, 0, 0.8);
	font-size: 32px;
}

.about-title-large {
	font-size: 32px;
	line-height: 38px;
}

.about-title-mobile {
	font-size: 22px;
	line-height: 26px;
}

.about-welcome-desktop {
	display: flex;
	width: 100%;
}

.about-welcome-desktop > section {
	width: 100%;
}

.about-welcome-tablet,
.about-welcome-mobile {
	display: flex;
	flex-direction: column;
	z-index: 1;
}

.welcome-section {
	.about-page-text-body-container {
		height: auto;
		// min-height: 160px;
	}
}

.welcome-section-desktop {
	margin-right: 1.5rem;
	margin-bottom: 20px;
}

.welcome-section-tablet {
	max-width: 100%;
}
.welcome-section-mobile {
	max-height: 100%;
	align-items: center;
	margin-right: 0;
	z-index: 1;
	width: 100%;

	.app-card {
		width: 100%;
	}
}

.welcome-section-mobile:first-child,
.welcome-section-tablet:first-child {
	margin-bottom: 24px;
}

.about-section-title {
	@include cardTitle();
	padding-bottom: 16px;
	gap: 10px;
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	white-space: pre-line;
}

.contact-section-container-desktop {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	gap: 20px;
	max-width: 100%;
}

.contact-section-container-mobile {
	display: flex;
	flex-direction: column;
	align-items: space-between;
	gap: 20px;
	width: 100%;
	overflow-x: auto;
	margin-top: 20px;
}

.contact-section-container-tablet {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
	width: 100%;
	overflow-x: auto;
	margin-top: 20px;
}

.contact-section-title {
	@include cardTitle();
	padding-bottom: 16px;
	gap: 10px;
	display: flex;
	align-items: left;
	margin-bottom: 15px;
	justify-content: left;
	// height: 80px;
}

.contact-card {
	.app-button-container {
		text-align: center;

		a {
			margin: 0 auto;
			min-width: 180px;
			width: 60%;
		}
	}
}

.contact-card-desktop {
	flex: 1;
	max-width: 430px;
}

.contact-card-tablet {
	min-height: 319px;
	flex-shrink: 0.2;

	.app-button-container {
		a {
			min-width: 200px;
			width: 80%;
		}
	}
}

.contact-card-mobile {
	height: 300px;
	flex-shrink: 0.1;

	.app-button-container {
		a {
			width: 100%;
		}
	}
}

.contact-card-wide-desktop {
	flex: 1;
	max-width: 650px;
	min-height: 230px;
}

.contact-card-wide-tablet {
	min-height: 200px;
	flex-shrink: 0.2;
	width: 100%;

	.app-button-container {
		a {
			// min-width: 200px;
			width: 80%;
		}
	}
}

.contact-card-wide-mobile {
	height: 300px;
	flex-shrink: 0.1;

	.app-button-container {
		a {
			width: 100%;
		}
	}
}

.about-page-btn-container {
	display: flex;
	flex-direction: column;
	align-self: center;
	text-align: center;
	padding: 24px 36px 36px 36px;
	font-size: 16px;
}

.about-page-card-footer-desktop {
	display: flex;
	justify-content: center;
	margin-top: 36px;
}

.about-page-card-footer-tablet {
	display: flex;
	justify-content: center;
	margin-top: auto;
}

.about-page-card-footer-mobile {
	display: flex;
	justify-content: center;
}

.about-app-button-primary {
	@include buttonPrimary;
	font-size: 16px;
	height: 10px;
	padding: 24px 36px 36px 36px;
	justify-content: center;
	text-align: center;
}

.section-title-last {
	@media screen and (max-width: 377px) {
		margin-top: 130px
	}
}